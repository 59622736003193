import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { Dialog, TransitionChild, DialogPanel, DialogTitle, DialogBackdrop } from "@headlessui/react"

import Config from "../../api/Config"
import { Thematic } from "../utils/Thematic"
import { IdentityHeader } from "./IdentityHeader"
import { useAppSelector } from "../../redux/hooks";
import { standardRoutes } from "../../routes/standard"

export const StandardHeader = () => {
    const [scrolled, setScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const identityState: any = useAppSelector(state => state.identity);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getStandardRoute = (keyString: string) => {
        return (standardRoutes.find(
            (routeName) => routeName.name === keyString)
        )?.path
    }

    return (
        <React.Fragment>
            <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
                <link href="https://fonts.googleapis.com/css2?family=Silkscreen:wght@400;700&display=swap" rel="stylesheet" />
            </Helmet>

            <nav className={`border-b border-gray-300 dark:border-gray-600 h-16 md:pl-16 md:px-0 px-2 fixed z-10 top-0 left-0 w-full transition duration-300 ${scrolled ? 'backdrop-blur-md bg-white/70 dark:bg-gray-800/70' : 'bg-transparent'}`}>
                <div className="max-w-full mx-auto md:px-4 px-2">
                    <div className="relative flex items-center justify-between h-16 w-full">
                        <div className="inset-y-0 w-full left-0 sm:hidden">
                            <div className="flex w-full items-center gap-x-3">
                                <button type="button" onClick={() => setIsMenuOpen(!isMenuOpen)} className="inline-flex items-center justify-center rounded-md bg-inherit text-primary dark:text-secondary hover:text-primary-dark focus:outline-none" aria-controls="mobile-menu" aria-expanded={isMenuOpen ? 'true' : 'false'}>
                                    <span className="sr-only">Open main menu</span>
                                    {
                                        isMenuOpen ? (
                                            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        ) : (
                                            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                            </svg>
                                        )
                                    }
                                </button>

                                <div className="flex-grow">
                                    <Link to="/home" className={`text-theme mb-0 silkscreen-regular px-0 py-2 rounded-md`} aria-current="page">
                                        <span className="text-2xl tracking-wide">
                                            {Config.APP_NAME}
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>


                        <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="hidden sm:block">
                                <div className="flex space-x-4">
                                    <Link to="/home" className={`text-theme mb-0 silkscreen-regular px-0 py-2 rounded-md`} aria-current="page">
                                        <span className="text-2xl tracking-wide">
                                            {Config.APP_NAME}
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className=" inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <IdentityHeader />
                        </div>
                    </div>
                </div>

                <Dialog open={isMenuOpen} onClose={() => setIsMenuOpen(!isMenuOpen)} className="relative z-10">
                    <DialogBackdrop transition className="fixed inset-0 bg-gray-500/75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0" />

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pr-10">
                                <DialogPanel transition className="pointer-events-auto relative w-screen max-w-md transform transition-all duration-500 ease-in-out data-[closed]:-translate-x-full sm:duration-700">
                                    <Thematic>
                                        <TransitionChild>
                                            <div className="absolute right-0 top-0 -mr-10 flex pr-2 pt-4 duration-500 ease-in-out data-[closed]:opacity-0 sm:-mr-10 sm:pr-4">
                                                <button type="button" onClick={() => setIsMenuOpen(!isMenuOpen)} className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </TransitionChild>

                                        <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-slate-800 shadow-xl text-slate-600 dark:text-slate-400">
                                            <div className="px-4 sm:px-6 border-b border-gray-300 dark:border-gray-600 py-4">
                                                <DialogTitle className="text-base font-semibold text-slate-600 dark:text-slate-400 flex items-center gap-x-3">
                                                    <div className="flex-none">
                                                        <Link to="/home" onClick={() => setIsMenuOpen(false)} className={`text-theme mb-0 silkscreen-regular px-0 py-2 rounded-md`}>
                                                            <span className="fa-duotone fa-house fa-xl"></span>
                                                        </Link>
                                                    </div>

                                                    <span className="border-l border-gray-300 dark:border-gray-600 py-4"></span>

                                                    <div className="flex-grow">
                                                        <Link to="/home" onClick={() => setIsMenuOpen(false)} className={`text-theme mb-0 silkscreen-regular px-0 py-2 rounded-md`} aria-current="page">
                                                            <span className="text-2xl tracking-wide">
                                                                {Config.APP_NAME}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </DialogTitle>
                                            </div>

                                            <div className="relative mt-3 flex-1 px-4 sm:px-6 flex-grow overflow-y-auto">
                                                <ul className="w-full">
                                                    <li className="w-full border-b border-gray-300 dark:border-gray-600">
                                                        <Link to={getStandardRoute('ASSET.REGISTRY_')} onClick={() => setIsMenuOpen(false)} className="w-full flex gap-x-3 items-center align-middle rounded-md px-2 py-2 text-sm data-[focus]:bg-gray-200 data-[focus]:text-secondary dark:data-[focus]:bg-gray-200">
                                                            <div className="flex-none w-9 h-9 flex items-center justify-center">
                                                                <span className="fa-light fa-cassette-betamax fa-xl block"></span>
                                                            </div>

                                                            <span className="w-3/4 text-start">Asset Management</span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="px-4 w-full border-t border-gray-300 dark:border-gray-600">
                                                <div className="flex items-center gap-x-3 py-2.5">
                                                    <div className="flex-grow text-theme-dark dark:text-theme">
                                                        <span className="text-start block">
                                                            {identityState.names.display}
                                                        </span>
                                                    </div>

                                                    <span className="border-l border-gray-300 dark:border-gray-600 py-4"></span>

                                                    <div className="flex-none">
                                                        <Link to={undefined} onClick={() => setIsMenuOpen(false)} className={`mb-0 silkscreen-regular px-0 py-2 rounded-md`}>
                                                            <span className="fa-light fa-gear fa-xl"></span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Thematic>
                                </DialogPanel>
                            </div>
                        </div>
                    </div>
                </Dialog>

            </nav>

        </React.Fragment>
    )
}