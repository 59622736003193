import React, { useState } from "react"

import { TwoToneModal } from "../../lib/fragments/TwoToneModal"

export const AddAttribute = ({ show, showOrHide, onComplete }) => {
    const [state, setstate] = useState({
        whatToAdd: null,
    })

    const whatToAddList = [
        { name: "Section", icon: "fa-block-question", description: "Add a new section to group related fields" },
        { name: "Text Input", icon: "fa-text-size", description: "Capture single or multi-line textual info" },
        { name: "Date Input", icon: "fa-calendar", description: "Capture date specific inputs" },
        { name: "Choice Input", icon: "fa-ballot-check", description: "Predefined dropdowns, checkboxes, or radio buttons" },
        { name: "Asset Link", icon: "fa-link", description: "Link existing assets for dynamic references" },
    ]

    const selectWhatToAdd = (itemToAdd: any) => {
        setstate((prev) => ({
            ...prev,
            whatToAdd: itemToAdd
        }))
    }

    const backToWhatToAddList = () => {
        setstate((prev) => ({
            ...prev,
            whatToAdd: null
        }))
    }

    return (
        <React.Fragment>
            <TwoToneModal
                size={"3xl"}
                show={show}
                status={'fulfilled'}
                posting={false}
                showOrHide={showOrHide}
                formAction={"Add Attribute"}
                onSubmit={undefined}
                header={
                    <>
                        <div className="w-full flex flex-col h-full gap-y-2.5 px-2">
                            <div className="flex md:pb-0 pb-3 md:pt-2 pt-4 flex-row-reverse align-middle items-center">
                                <button type="button" className="rounded-md md:hidden text-gray-800 dark:text-gray-200 hover:text-gray-800 focus:outline-none flex flex-row align-middle pr-3"
                                    onClick={showOrHide}>
                                    <span className="fas fa-times border-none text-lg"></span>
                                    <span className="sr-only text-sm ml-3">Close panel</span>
                                </button>

                                <div className="flex-grow">
                                    <h2 className="md:text-lg text-xl font-medium tracking-wider text-slate-600 dark:text-white md:text-slate-600 dark:md:text-white">
                                        Add an Attribute
                                    </h2>
                                </div>
                            </div>

                            <div className="sm:col-span-8 flex-grow lg:col-span-7 mb-3 flex flex-col gap-y-3 text-primary dark:text-slate-100 md:text-slate-600 dark:md:text-slate-400">
                                <span className="block md:py-2 text-sm">
                                    Tailor fields to specific assets and link them with other existing assets for more detailed tracking.
                                </span>
                            </div>
                        </div>
                    </>
                }
                contents={
                    <>
                        <div className="w-full px-4">
                            {
                                !state.whatToAdd ? (
                                    <>
                                        <p className="text-sm mb-3">
                                            What would like to add?
                                        </p>

                                        <ul className={`w-full space-y-3 text-slate-500 dark:text-slate-400 transition-transform duration-700 ease-in-out`}>
                                            {
                                                whatToAddList.map((toAdd: any) => (
                                                    <button key={toAdd.icon} onClick={() => selectWhatToAdd(toAdd)} type="button" className="w-full flex items-center text-start gap-x-3 hover:bg-slate-100 dark:hover:bg-gray-700 ring-1 ring-slate-900/15 dark:ring-slate-200/35 rounded-md md:py-2 py-3 px-4">
                                                        <div className="flex-none w-9 text-slate-900 dark:text-white md:flex hidden">
                                                            <span className={`fa-light ${toAdd.icon} fa-xl`}></span>
                                                        </div>

                                                        <div className="flex-grow">
                                                            <div className="text-sm md:block text-slate-900 dark:text-white flex items-center gap-x-2">
                                                                <span className="md:hidden w-9">
                                                                    <span className={`fa-light ${toAdd.icon} fa-xl`}></span>
                                                                </span>
                                                                {toAdd.name}
                                                            </div>

                                                            <span className="text-sm md:block hidden">
                                                                {toAdd.description}
                                                            </span>
                                                        </div>
                                                    </button>
                                                ))
                                            }
                                        </ul>
                                    </>
                                ) : (
                                    <>
                                        <button type="button" onClick={backToWhatToAddList} className="flex flex-row items-center gap-x-3 align-middle py-1.5 text-sm text-theme dark:text-theme hover:text-secondary">
                                            <i className="fa-duotone fa-arrow-left fa-lg"></i>
                                            Back
                                        </button>

                                        <p className="text-base text-slate-900 dark:text-white flex items-center gap-x-3 py-2">
                                            <span className={`fa-light ${state.whatToAdd.icon} fa-xl`}></span>

                                            {state.whatToAdd.name}
                                        </p>

                                        <p className="text-sm mb-3 md:hidden">
                                            {state.whatToAdd.description}
                                        </p>
                                    </>
                                )
                            }





























                        </div>
                    </>
                }
            />
        </React.Fragment>
    )
}