import ReactDOM from "react-dom"
import React, { FC, Fragment } from "react"
import { Transition, Dialog, TransitionChild, DialogPanel } from "@headlessui/react"

import { classNames } from "../../utils/Helpers"
import { BespokeProps } from "../../utils/Interfaces"
import { Loading } from "../../components/utils/Loading"
import { Thematic } from "../../components/utils/Thematic"

export const BespokePanel: FC<BespokeProps> = ({
    show,
    status,
    title,
    showOrHide,
    description,
    components,
    size = 'md'
}) => {
    return ReactDOM.createPortal(
        <React.Fragment>
            <Transition show={show} as={Fragment}>
                <Dialog as="div" className="relative z-30" onClose={showOrHide}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </TransitionChild>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                                <TransitionChild
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <DialogPanel className={classNames(
                                        `max-w-${size}`,
                                        'pointer-events-auto relative'
                                    )}>
                                        <Thematic>
                                            {
                                                status === 'rejected' ? (
                                                    <>
                                                    </>
                                                ) : status === 'fulfilled' ? (
                                                    <>
                                                        <div className="flex h-screen flex-col bg-white shadow-xl overflow-y-auto">
                                                            <div className="w-full px-4 py-4 md:px-5 bg-light flex-none">
                                                                <div className="top-0 left-0 flex pt-2 pb-3 flex-row-reverse align-middle items-center">
                                                                    <button
                                                                        type="button"
                                                                        className="rounded-md text-gray-800 hover:text-gray-800 focus:outline-none flex flex-row align-middle pr-3"
                                                                        onClick={showOrHide}
                                                                    >
                                                                        <span className="fas fa-times border-none text-lg"></span>
                                                                        <span className="sr-only text-sm ml-3">Close panel</span>
                                                                    </button>

                                                                    <div className="flex-grow">
                                                                        <h2 className="text-lg text-primary">
                                                                            {title}
                                                                        </h2>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    description && (
                                                                        <div className="top-0 left-0 flex">
                                                                            <p className="text-sm text-gray-800">
                                                                                {description}
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>

                                                            {
                                                                components
                                                            }
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="py-2 px-6 flex h-screen flex-col bg-white shadow-xl overflow-y-auto align-middle items-center justify-center">
                                                        <Loading />
                                                    </div>
                                                )
                                            }
                                        </Thematic>
                                    </DialogPanel>
                                </TransitionChild>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </React.Fragment>,
        document.getElementById('root')
    )
}
