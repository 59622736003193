import { Home } from "../views/home/Home";
import { Routes_Interface } from "./routesInterface";
import { AssetRegistry } from "../views/assets/AssetRegistry";
import { AssetConfiguration } from "../views/assets/AssetConfiguration";
import { AssetTypesListing } from "../views/assets/AssetTypesListing";

export const standardRoutes: Array<Routes_Interface> = [
    {
        name: 'HOME_',
        path: "/home",
        element: <Home />,
        caseSensitive: true,
    },
    {
        name: 'ASSET.REGISTRY_',
        path: "/module/asset-management/registry",
        element: <AssetRegistry />,
        caseSensitive: true,
        activeMenu: 'ASST'
    },
    {
        name: 'ASSET.CONFIG_',
        path: "/module/asset-management/configuration",
        element: <AssetConfiguration />,
        caseSensitive: true,
        activeMenu: 'ASST'
    },
    {
        name: 'ASSET.TYPE_LISTING_',
        path: "/module/asset-management/configuration/:uuid",
        element: <AssetTypesListing />,
        caseSensitive: true,
        activeMenu: 'ASST'
    },
]