import React from "react"
import { Helmet } from "react-helmet"

import Config from "../../api/Config"

export const Home = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>{Config.APP_NAME} Home</title>
            </Helmet>

            <div className="flex items-center justify-center h-full bg-theme from-[#ffffff] via-[#ffffff] via-[40%] to-gradient">
                <h1 className={`text-primary`}>
                    Welcome  Home
                </h1>
            </div>
        </React.Fragment>
    )
}