import { Menu, MenuButton, MenuItems } from '@headlessui/react'

export default function DropDown({ button, items }) {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton className="flex flex-row align-middle items-center gap-x-1.5 bg-inherit text-muted border border-gray-300 py-2 px-3 rounded-md hover:text-secondary shadow-sm hover:bg-gray-50">
                    <span className="fa-regular fa-ellipsis-vertical"></span>
                </MenuButton>
            </div>

            <MenuItems transition className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                <div className="p-1.5">
                    {
                        items
                    }
                </div>
            </MenuItems>
        </Menu>
    )
}
