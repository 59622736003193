import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"

import { useAppSelector } from "../../redux/hooks";
import { classNames, displayNameColoring } from "../../utils/Helpers"
import { useDispatch } from "react-redux";
import { revokeIdentity } from "../../redux/idenTT_Actions";

export const IdentityHeader = () => {
    const dispatch: any = useDispatch()
    const identityState: any = useAppSelector(state => state.identity);

    const signOutHandler = () => {
        dispatch(revokeIdentity())
    }

    return (
        <React.Fragment>
            <Menu as="div" className="relative inline-block text-left float-right">
                {({ open }) => (
                    <>
                        <div className='flex flex-row w-full'>
                            <MenuButton
                                className={
                                    classNames(
                                        open ? 'text-slate-700' : null,
                                        "flex flex-row items-center w-auto md:px-3 px-1 gap-x-3 rounded py-1 text-sm text-slate-500 hover:bg-slate-00 hover:text-slate-700 focus:outline-none focus:ring-0 focus:ring-offset focus:ring-offset-slate-100 focus:ring-green-500 align-middle"
                                    )
                                }>

                                {
                                    !identityState.pic_url ? (
                                        <>
                                            {
                                                identityState.names.display && (
                                                    <div className={`md:w-9 md:h-9 w-10 h-10 flex items-center justify-center align-middle rounded-full ${displayNameColoring(identityState.names.display.charAt(0))}`}>
                                                        <span className="text-white text-lg font-medium">
                                                            {identityState.names.display.charAt(0)}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <img className="rounded-full md:w-9 md:h-9 w-10 h-10" src={identityState.pic_url} alt={`${identityState.names.display}'s avatar`} />
                                    )
                                }

                                <span className="text-sm hidden md:flex dark:text-white">{identityState.names.display}</span>
                            </MenuButton>
                        </div>

                        <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <MenuItems className="w-60 py-1 origin-top-right absolute right-0 mt-3 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <MenuItem>
                                    {({ focus }) => (
                                        <Link
                                            to={undefined}
                                            className={classNames(
                                                focus ? 'bg-slate-100 text-slate-800' : 'text-slate-700',
                                                'px-4 py-3 text-sm text-left w-full block mt-1'
                                            )}
                                        >
                                            <span className="flex flex-row align-middle items-center pl-1">
                                                <span className="w-7">
                                                    <i className="fa-light m-auto fa-user-crown text-base"></i>
                                                </span>

                                                <span className="ml-2 flex-auto">
                                                    My Account
                                                </span>
                                            </span>
                                        </Link>
                                    )}
                                </MenuItem>

                                <MenuItem>
                                    {({ focus }) => (
                                        <button
                                            onClick={signOutHandler}
                                            className={classNames(
                                                focus ? 'bg-red-100 text-red-800' : 'text-red-700',
                                                'px-4 py-3 text-sm text-left w-full block'
                                            )}
                                        >
                                            <span className="flex flex-row align-middle items-center pl-1">
                                                <span className="w-7">
                                                    <i className="fa-light m-auto fa-sign-out text-base"></i>
                                                </span>

                                                <span className="ml-2 flex-auto">
                                                    Sign Out
                                                </span>
                                            </span>
                                        </button>
                                    )}
                                </MenuItem>
                            </MenuItems>
                        </Transition>
                    </>
                )}
            </Menu>
        </React.Fragment>
    )
}