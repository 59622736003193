import React from "react"
import { Link, useLocation } from "react-router-dom"

import { standardRoutes } from "../../routes/standard"

export const SideMenu = ({ location }) => {
    const getStandardRoute = (keyString: string) => {
        return (standardRoutes.find(
            (routeName) => routeName.name === keyString)
        )?.path
    }

    return (
        <React.Fragment>
            <aside className="md:w-16 hidden md:block bg-secondary text-gray-200 fixed top-0 left-0 h-full shadow-lg z-10">
                <ul className="flex flex-col items-center mt-4 w-full space-y-4">
                    <li>
                        <Link to={undefined} className=" h-10 w-10 flex items-center justify-center">
                            <span className="fa-light fa-home fa-lg"></span>
                        </Link>
                    </li>

                    <li>
                        <Link to={getStandardRoute('ASSET.REGISTRY_')} className="h-10 w-10 flex items-center justify-center" data-tooltip-target="tooltip-top" data-tooltip-placement="top">
                            <span className="fa-light fa-cassette-betamax fa-lg"></span>
                        </Link>
                    </li>

                    <li>
                        <Link to={undefined} className="h-10 w-10 flex items-center justify-center">
                            <span className="fa-light fa-gear fa-lg"></span>
                        </Link>
                    </li>
                </ul>


            </aside >
        </React.Fragment>
    )
}