import React, { FC } from "react"

import { classNames } from "../../utils/Helpers"
import { InputProps } from "../../utils/Interfaces"

export const InputTextArea: FC<InputProps> = ({
    name,
    value,
    error,
    label,
    posting,
    required,
    blurHandler,
    changeHandler,
}) => {
    let placeHolder = label
        ? (label.charAt(0).toUpperCase() + label.slice(1).toLowerCase())
        : (name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())

    return (
        <React.Fragment>
            {
                label && (
                    <label htmlFor={name} className="block text-sm leading-6 text-stone-600 mb-1">{label}:</label>
                )
            }

            <div className="relative mt-2 rounded shadow-sm">
                <textarea name={name} id={name} placeholder={placeHolder} autoComplete="off" rows={4} cols={1}
                    className={classNames(
                        error.length > 0 ?
                            'text-red-900 ring-slate-300 placeholder:text-red-400 focus:ring-red-600 focus:outline-red-500 border border-red-600' :
                            'text-gray-900 ring-slate-300 placeholder:text-gray-400 focus:ring-primary focus:outline-primary hover:border-stone-400 border border-stone-300',
                        'block w-full rounded-md py-2 pl-3 pr-8 border border-gray-300 resize-none text-sm disabled:text-gray-600 disabled:bg-inherit disabled:cursor-not-allowed focus:border-0'
                    )} onChange={changeHandler} disabled={posting} value={value} onBlur={blurHandler} required={required} />

                <div className="absolute inset-y-0 right-0 flex items-center w-8">
                    {
                        error.length > 0 ? (
                            <span className="fa-duotone text-red-500 fa-circle-exclamation fa-lg"></span>
                        ) : null
                    }
                </div>
            </div>

            {
                error.length > 0 ? (
                    <span className='invalid-feedback text-xs text-red-600 pl-1 pt-0.5'>
                        {error}
                    </span>
                ) : null
            }
        </React.Fragment>
    )
}