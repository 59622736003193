import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom";

import Authenticator from "./Authenticator";
import { useAppSelector } from "../../redux/hooks";
import { Thematic } from "../../components/utils/Thematic";
import { revokeIdentity } from "../../redux/idenTT_Actions";

export default function AuthGuard() {
    const dispatch: any = useDispatch()
    const location: any = useLocation()
    const locationState: any = location.state

    const identitySession = useAppSelector(state => state.identity);
    const sessionState = Authenticator.Check(identitySession);

    const hasRevokedIdentity = useRef(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        if (sessionState.resetSession && !hasRevokedIdentity.current) {
            dispatch(revokeIdentity());

            setShouldRedirect(true);
            hasRevokedIdentity.current = true;
        }
    }, [sessionState, dispatch]);

    if (shouldRedirect) {
        return <Navigate to="/auth/sign-in" replace />;
    }

    if (sessionState.resetSession) {
        /* 
         * Redux session state is authenticated
         * but cookies are not set.
         * 
         * Reset session and start all-over again
        */
        return
    } else if (sessionState.authenticated) {
        if (!locationState?.from) {
            return <Navigate replace to="/home" />
        } else {
            return <Navigate replace to={locationState.from} />
        }
    }

    return (
        <Thematic>
            <Outlet />
        </Thematic>
    )
}