import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import React, { useEffect, useState } from "react"

import Config from "../../api/Config"
import { ASSETS } from "../../api/Registry"
import { CreateCategory } from "./CreateCategory"
import HttpServices from "../../services/HttpServices"
import { LoadingXL } from "../../components/utils/LoadingXL"
import { ModuleBreadCrumb } from "../../lib/fragments/ModuleBreadCrumb"

export const AssetConfiguration = () => {
    const [state, setstate] = useState({
        reload: false,
        httpStatus: 200,
        status: 'pending',
        data: {
            categories: null,
        },
        show: {
            createType: false,
            createCategory: false,
        }
    })

    const pageTitle = "Asset Configuration"
    const [newCategory, setNewCategory] = React.useState(null)

    const breadCrumb = [
        { linkItem: false, title: "Asset Management" },
        { linkItem: false, title: "Configuration" },
    ]

    const fetchAssetCategories = async () => {
        let { status } = state
        let { httpStatus } = state

        let categories = null

        try {
            const response: any = await HttpServices.httpGet(ASSETS.ALL_CATEGORIES)
            httpStatus = response.status

            if (httpStatus === 200) {
                const payload: any = response.data.payload

                status = 'fulfilled'
                categories = payload.categories
            } else {
                status = 'rejected'
            }
        } catch (error) {
            status = 'rejected'
            httpStatus = 500
        }

        setstate((prevState) => ({
            ...prevState,
            status,
            httpStatus,
            reload: false,
            data: {
                ...prevState.data,
                categories: categories,
            },
        }));
    }

    useEffect(() => {
        fetchAssetCategories()
    }, [])

    const toggleCreateCategoryPanel = () => {
        setstate((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                createCategory: !prev.show.createCategory
            }
        }))
    }

    const onCreateCategoryComplete = (actionData: any) => {
        toggleCreateCategoryPanel()
        setNewCategory(actionData)
    }

    const reloadTable = () => {
        setstate((prevState) => ({
            ...prevState, reload: !prevState.reload
        }));

        fetchAssetCategories()
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{pageTitle} - {Config.APP_NAME}</title>
            </Helmet>

            <div className="flex flex-col gap-x-3 md:px-3.5 w-full mx-auto py-3 md:h-full lg:h-full px-5 md:container md:max-w-4xl lg:max-w-4xl xl:max-w-5xl">
                <div className="flex flex-col gap-x-3 px-3.5 w-full rounded-md bg-light dark:bg-light mx-auto py-3">
                    <div className="w-full flex flex-row md:h-44 align-middle items-center md:px-8">
                        <div className="header-title align-middle title-transition">
                            <h1 className="title md:text-2xl text-xl font-meidum tracking-wider leading-9 md:pb-4 pb-3 text-theme dark:text-theme-dark">
                                {pageTitle}
                            </h1>

                            <p className="text-sm md:mb-5 text-wrap dark:text-primary">
                                <span className="hidden md:inline-block">
                                    Manage and customize asset attributes, categories, and types to ensure all assets are accurately represented and tailored to your organization's needs.
                                </span>

                                <span className="md:hidden">
                                    Customize and manage attributes for different asset types
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="w-full pt-4 md:px-4">
                    <ModuleBreadCrumb breadCrumb={breadCrumb} />
                </div>

                {
                    state.status === 'rejected' ? (
                        <>
                        </>
                    ) : state.status === 'fulfilled' ? (
                        <>
                            <div className="w-full flex md:flex-row flex-col md:align-middle md:items-center mb-3 md:px-4">
                                <div className="flex-grow md:border-0 border-b border-gray-300 dark:border-gray-600 pb-2 md:mb-0 mb-4">
                                    <h1 className="title text-lg tracking-wider leading-9 text-theme dark:text-theme">
                                        Asset Categories
                                    </h1>
                                </div>

                                <div className="flex-grow">
                                    <div className="w-full flex flex-row-reverse md:px-2  ">
                                        <div className="w-auto flex flex-row-reverse align-middle items-center gap-x-2">
                                            <button type="button" onClick={reloadTable} className="bg-inherit hover:bg-gray-200 dark:hover:bg-gray-600 hover:bg-light py-1.5 px-2 rounded-full text-theme gap-x-3 align-middle text-sm">
                                                <i className="fa-duotone fa-rotate-right fa-xl"></i>
                                            </button>

                                            <button type="button" onClick={toggleCreateCategoryPanel} className="flex flex-row items-center gap-x-3 align-middle py-1.5 text-sm text-theme dark:text-theme hover:text-secondary">
                                                <i className="fa-duotone fa-plus fa-lg"></i>
                                                Add Asset Type
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="bg-white dark:bg-slate-800 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-l">
                                <div>
                                    <span className="inline-flex items-center justify-center p-2 bg-indigo-500 rounded-md shadow-l">
                                        <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"></svg>
                                    </span>
                                </div>
                                <h3 className="text-slate-900 dark:text-white mt-5 text-base font-medium tracking-tight">Writes Upside-Down</h3>
                                <p className="text-slate-500 dark:text-slate-400 mt-2 text-sm">
                                    The Zero Gravity Pen can be used to write in any orientation, including upside-down. It even works in outer space.
                                </p>
                            </div>

                            <ul className="w-full relative pb-20">
                                {
                                    state.reload ? (
                                        <div className="absolute inset-0 bg-white dark:bg-slate-800 dark:bg-opacity-70 bg-opacity-70 flex items-center justify-center">
                                            <span className="fa-duotone text-theme fa-spinner-third fa-2x m-auto block fa-spin"></span>
                                        </div>
                                    ) : null
                                }

                                {
                                    newCategory && (
                                        <li key={newCategory.uuid} className="bg-inherit hover:bg-gray-100 dark:hover:bg-gray-900 border-b border-gray-300 dark:border-gray-600 text-sm flex flex-row align-middle items-center">
                                            <div className="flex-grow py-3 md:px-4">
                                                <span className="text-theme dark:text-theme block text-base">
                                                    {newCategory.name}
                                                </span>

                                                <span className="block">
                                                    {newCategory.description}
                                                </span>
                                            </div>

                                            <div className="flex-none py-3 md:px-4">
                                                <Link to={newCategory.uuid} className="text-sm text-theme dark:text-theme-secondary">
                                                    View
                                                </Link>
                                            </div>
                                        </li>
                                    )
                                }

                                {
                                    state.data.categories.map((category: any) => {
                                        return (
                                            <li key={category.uuid} className="bg-inherit hover:bg-gray-100 dark:hover:bg-gray-900 border-b border-gray-300 dark:border-gray-600 text-sm flex flex-row align-middle items-center">
                                                <div className="flex-grow py-3 md:px-4">
                                                    <h3 className="text-slate-900 dark:text-white text-base font-medium tracking-tight">
                                                        {category.name}
                                                    </h3>

                                                    <p className="mt-2 text-sm">
                                                        {category.description}
                                                    </p>
                                                </div>

                                                <div className="flex-none py-3 md:px-4">
                                                    <Link to={category.uuid} className="text-sm text-theme dark:text-theme-secondary">
                                                        View
                                                    </Link>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </>
                    ) : (
                        <div className="flex-grow w-full overflow-auto h-full flex flex-row py-4 justify-center align-middle items-center">
                            <LoadingXL />
                        </div>
                    )
                }
            </div>

            <CreateCategory
                show={state.show.createCategory}
                onComplete={onCreateCategoryComplete}
                showOrHide={toggleCreateCategoryPanel}
            />
        </React.Fragment>
    )
}