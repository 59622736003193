import { useDispatch } from "react-redux";
import { useRef, useState, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import Authenticator from "./Authenticator";
import { errorRoutes } from "../../routes/error";
import { useAppSelector } from "../../redux/hooks";
import { Thematic } from "../../components/utils/Thematic";
import { revokeIdentity } from "../../redux/idenTT_Actions";

export default function TrustGuard() {
    const dispatch: any = useDispatch();
    const identitySession = useAppSelector(state => state.identity);

    const location = useLocation()
    const locationState: any = location.state

    console.log('Trust Gate', location);
    const sessionState = Authenticator.Check(identitySession);

    const hasRevokedIdentity = useRef(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        if (sessionState.resetSession && !hasRevokedIdentity.current) {
            dispatch(revokeIdentity());

            setShouldRedirect(true);
            hasRevokedIdentity.current = true;
        }
    }, [sessionState, dispatch]);

    if (shouldRedirect) {
        return <Navigate to="/auth/sign-in" replace />;
    }

    if (sessionState.resetSession) {
        /* 
         * Redux session state is authenticated
         * but cookies are not set.
         * 
         * Reset session and start all-over again
        */
        return
    } else if (!sessionState.authenticated) {
        return <Navigate to="/auth/sign-in" replace state={locationState ? locationState.from : null} />;
    } else if (sessionState.statusExists) {
        if (sessionState.status === 'ACTIVE') {
            if (!locationState?.from) {
                return <Navigate to={'/home'} replace />;
            } else {
                return <Navigate replace to={locationState.from} />
            }

        } else {
            const accountStatusRoute: any = (
                errorRoutes.find((routeName) => routeName.name === 'BNF_STATUS_')
            )?.path

            return <Navigate to={accountStatusRoute} replace />;
        }
    }

    return (
        <Thematic>
            <Outlet />
        </Thematic>
    )
}