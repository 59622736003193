import React, { useState } from "react"
import { ListboxButton } from "@headlessui/react"

import { ListBox } from "../../lib/fragments/ListBox"
import { InputBasic } from "../../components/utils/InputBasic"
import { BespokePanel } from "../../lib/fragments/BespokePanel"
import { InputTextArea } from "../../components/utils/InputTextArea"
import { G_onInputChangeHandler, G_onInputBlurHandler } from "../../components/utils/InputHandlers"
import { toast } from "react-toastify"
import { ASSETS } from "../../api/Registry"
import HttpServices from "../../services/HttpServices"
import { APPLICATION } from "../../utils/Constants"
import { API_RouteReplace } from "../../utils/Helpers"

export const CreateType = ({ show, showOrHide, category, onComplete }) => {
    const [state, setstate] = useState({
        posting: false,
        httpStatus: 200,
        status: 'fulfilled',
        input: {
            name: '',
            description: ''
        },
        errors: {
            name: '',
            description: ''
        }
    })

    const onChangeHandler = (e: any) => {
        let { posting } = state

        if (!posting) {
            let { input } = state
            let { errors } = state
            let output: any = G_onInputChangeHandler(e)

            const updatedInput = {
                ...input,
                [e.target.name]: output.value
            };

            const updatedErrors = {
                ...errors,
                [e.target.name]: output.error
            };

            setstate((prev) => ({
                ...prev,
                input: updatedInput,
                errors: updatedErrors,
            }));
        }
    }

    const onInputBlur = (e: any) => {
        let { posting } = state

        if (!posting) {
            let { input } = state
            let { errors } = state
            let output: any = G_onInputBlurHandler(e, '')

            const updatedInput = {
                ...input,
                [e.target.name]: output.value
            };

            const updatedErrors = {
                ...errors,
                [e.target.name]: output.error
            };

            setstate((prev) => ({
                ...prev,
                input: updatedInput,
                errors: updatedErrors,
            }));
        }
    }

    const onFormSubmitHandler = (e: any) => {
        e.preventDefault()
        let { posting } = state

        if (!posting) {
            let validity = true
            let { input } = state

            const inputArray = Object.keys(input)

            inputArray.forEach((inputObject: any) => {
                let oTarget = e.target[inputObject]

                const event = {
                    target: {
                        name: oTarget.name,
                        required: oTarget.required,
                        value: oTarget.value.trim(),
                    }
                }

                onInputBlur(event)
            })

            let { errors } = state
            const errorArray = Object.keys(errors)

            errorArray.forEach((errorObject) => {
                if (errors[errorObject].length > 0) {
                    validity = false
                }
            })

            if (validity) {
                setstate((prev) => ({
                    ...prev,
                    posting: !prev.posting
                }))

                createNewAssetType()
            }
        }
    }

    const onChangeListBoxHandler = () => { }

    const createNewAssetType = async () => {
        let { input } = state
        let { errors } = state
        let { httpStatus } = state

        try {
            let formData = new FormData()
            formData.append('name', input.name)
            formData.append('description', input.description)

            let response: any = null
            let assetTypesRoute = null

            assetTypesRoute = API_RouteReplace(ASSETS.CATEGORY_TYPES, ':category', category.uuid)
            response = await HttpServices.httpPost(assetTypesRoute, formData)

            httpStatus = response.status

            if (httpStatus === 200) {
                const payload: any = response.data.payload

                const updatedInput = {
                    ...input,
                    name: '',
                    description: '',
                };

                setstate((prev) => ({
                    ...prev,
                    input: updatedInput,
                }));

                let onCompleteData = {
                    name: input.name,
                    uuid: payload.uuid,
                    category: category.uuid,
                    description: input.description,
                }

                onComplete(onCompleteData)
            } else {
                if (response.status === 422) {
                    Object.entries(response.data).forEach(([field, errorMessages]) => {
                        const submissionErrors = {
                            ...errors,
                            [field]: errorMessages
                        };

                        setstate((prev) => ({
                            ...prev,
                            errors: submissionErrors,
                        }));
                    });
                } else {
                    toast.error(APPLICATION.ERR_MSG, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        } catch (error) {
            httpStatus = 500
        }

        setstate((prevState) => ({
            ...prevState, httpStatus, posting: !prevState.posting
        }));
    }

    return (
        <React.Fragment>
            <BespokePanel
                show={show}
                status={state.status}
                title={"Create Asset Type"}
                showOrHide={showOrHide}
                description={"Fine tune your category grouping by creating asset types, like laptops or desktops"}
                components={
                    <form className="w-full flex-grow flex flex-col" onSubmit={onFormSubmitHandler}>
                        <div className="w-full flex-grow px-6 py-4">
                            <div className="w-full gap-y-2 mb-3 border-b pb-5 text-sm flex md:flex-row flex-col md:align-middle md:items-center">
                                <p className="flex-none md:w-1/3 text-stone-800">
                                    Category:
                                </p>

                                <div className="w-full max-w-64 rounded">
                                    <ListBox
                                        value={undefined}
                                        eventHandler={onChangeListBoxHandler}
                                        button={
                                            <>
                                                <ListboxButton className="relative w-full bg-slate-100 border border-slate-200 rounded-md py-2 px-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm" disabled={true}>
                                                    <span className="flex w-full items-center align-middle">
                                                        <div className="flex-grow flex items-center">
                                                            <span className="ml-3 block truncate">{category.name}</span>
                                                        </div>

                                                        <span className="fa-light fa-chevron-down float-end"></span>
                                                    </span>

                                                </ListboxButton>

                                            </>
                                        }
                                        options={null}
                                    />
                                </div>
                            </div>

                            <div className="mb-3">
                                <InputBasic
                                    name={"name"}
                                    required={true}
                                    label={"Type Name"}
                                    posting={state.posting}
                                    value={state.input.name}
                                    error={state.errors.name}
                                    blurHandler={onInputBlur}
                                    changeHandler={onChangeHandler}
                                />
                            </div>

                            <div className="mb-3">
                                <InputTextArea
                                    name={"description"}
                                    required={true}
                                    label={"Description"}
                                    posting={state.posting}
                                    value={state.input.description}
                                    error={state.errors.description}
                                    blurHandler={onInputBlur}
                                    changeHandler={onChangeHandler}
                                />
                            </div>
                        </div>

                        <div className="flex-none border-t py-4 px-5 w-full sm:flex sm:flex-row">
                            <div className="w-full space-y-px">
                                <div className="flex flex-row- items-center align-middle gap-x-4">
                                    <button type="submit" className="w-auto min-w-24 disabled:cursor-not-allowed text-sm rounded-md border border-transparent shadow-sm px-3 py-1.5 bg-primary text-white disabled:bg-primary hover:disabled:bg-primary hover:bg-secondary focus:outline-none" disabled={state.posting}>
                                        {
                                            state.posting ? (
                                                <span className="flex flex-row items-center align-middle h-5 justify-center">
                                                    <i className="fad fa-spinner-third fa-xl fa-spin"></i>
                                                </span>
                                            ) : (
                                                <span>Create</span>
                                            )
                                        }
                                    </button>

                                    <button type="button" onClick={showOrHide} className="text-sm flex-none shadow-none px-3 py-1 bg-inherit text-slate-600 hover:underline hover:cursor-pointer mr-2 sm:w-auto sm:text-sm">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                }
            />
        </React.Fragment>
    )
}