import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Sanctum } from 'react-sanctum';
import { ToastContainer } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'

import "tw-elements-react/dist/css/tw-elements-react.min.css";
import "react-toastify/dist/ReactToastify.css"
import './styles/App.css';
import './styles/global.css';
import './styles/toast.css';
import './resources/fontawesome/css/all.css'

import Config from './api/Config';
import { AUTH, SANCTUM } from './api/Registry';
import { PageNotFound } from './views/errors/PageNotFound';

import { errorRoutes } from './routes/error';
import { patronRoutes } from './routes/patron';
import { temporaryRoutes } from './routes/temp';
import { standardRoutes } from './routes/standard';
import { identityRoutes, trustRoutes } from './routes/identity';

import AuthGuard from './lib/guards/AuthGuard';
import TrustGuard from './lib/guards/TrustGuard';
import ErrorsGuard from './lib/guards/ErrorsGuard';
import PatronGuard from './lib/guards/PatronGuard';
import StandardGuard from './lib/guards/StandardGuard';
import { Thematic } from './components/utils/Thematic';

interface RouteContextType {
    currentpage: string,
    from: string
}

const RoutingContext = React.createContext<RouteContextType>(null!)

export default function App() {
    const axiosInstance = axios.create({
        headers: {
            'content-type': 'application/json',
        },
    });

    const sanctumConfig = {
        apiUrl: Config.APP_CORE,
        userObjectRoute: SANCTUM._USER,
        csrfCookieRoute: SANCTUM._COOKIE,
        signInRoute: AUTH.SS1_BENEFACTORS,
        signOutRoute: SANCTUM._SIGN_OUT,
        axiosInstance: axiosInstance
    }

    const RouterProvider = ({ children }: { children: React.ReactNode }) => {
        const currentLocation = useLocation()
        const [route, setRoute] = useState({
            currentpage: currentLocation.pathname,
            from: ''
        })

        useEffect(() => {
            setRoute((prev) => ({ currentpage: currentLocation.pathname, from: prev.currentpage }))
        }, [currentLocation])

        return <RoutingContext.Provider value={route}>
            {children}
        </RoutingContext.Provider>
    }

    return (
        <Thematic>
            <Router basename='/'>
                <Sanctum config={sanctumConfig}>
                    <RouterProvider>
                        <ToastContainer />

                        <Helmet>
                            <meta name="theme-color" content="#F97316" />
                        </Helmet>

                        <Routes>
                            <Route element={<PatronGuard />}>
                                {
                                    patronRoutes.map((route, index) => {
                                        return (
                                            <Route
                                                path={route.path}
                                                element={route.element}
                                                key={index}
                                            />
                                        )
                                    })
                                }
                            </Route>

                            <Route element={<AuthGuard />}>
                                {
                                    identityRoutes.map((route, index) => {
                                        return (
                                            <Route
                                                path={route.path}
                                                element={route.element}
                                                key={index}
                                            />
                                        )
                                    })
                                }
                            </Route>

                            <Route element={<TrustGuard />}>
                                {
                                    trustRoutes.map((route, index) => {
                                        return (
                                            <Route
                                                path={route.path}
                                                element={route.element}
                                                key={index}
                                            />
                                        )
                                    })
                                }
                            </Route>

                            <Route element={<StandardGuard />}>
                                {
                                    standardRoutes.map((route, index) => {
                                        return (
                                            <Route
                                                path={route.path}
                                                element={route.element}
                                                key={index}
                                            />
                                        )
                                    })
                                }
                            </Route>

                            <Route element={<ErrorsGuard />}>
                                {
                                    errorRoutes.map((route, index) => {
                                        return (
                                            <Route
                                                path={route.path}
                                                element={route.element}
                                                key={index}
                                            />
                                        )
                                    })
                                }
                            </Route>

                            {
                                temporaryRoutes.map((route, index) => {
                                    return (
                                        <Route element={
                                            route.guard === 'auth' ? <AuthGuard /> : null
                                        }>
                                            <Route
                                                path={route.path}
                                                element={route.element}
                                                key={index}
                                            />
                                        </Route>
                                    )
                                })
                            }

                            <Route path="*" element={<PageNotFound />} />

                        </Routes>
                    </RouterProvider>
                </Sanctum>
            </Router>
        </Thematic>
    );
}
