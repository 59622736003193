import { createContext } from "react"

import { APPLICATION, COOKIE_KEYS } from "../../utils/Constants"
import CookieServices from "../../services/CookieServices"

const ThemeContext = createContext('');

export const Thematic = ({ children }) => {
    const theme = CookieServices.get(COOKIE_KEYS.THEME) || APPLICATION.THEME

    return (
        <ThemeContext.Provider value={theme}>
            <div className={`w-full h-full theme-${theme} text-slate-600 dark:text-slate-400`}>

                {children}

            </div>
        </ThemeContext.Provider>
    );
}