import React from "react"
import { Link } from "react-router-dom"

export const ModuleBreadCrumb = ({ breadCrumb }) => {
    return (
        <React.Fragment>
            <ul className="w-full flex flex-wrap items-center pb-2 text-xs text-muted dark:text-gray-400 tracking-wide">
                <li>
                    <span className="h-5 flex gap-x-2 items-center">
                        <span className="fa-light fa-home"></span> Home
                    </span>
                </li>

                {
                    breadCrumb.map((crumb: any, index: number) => (
                        <React.Fragment key={`bread-crumb-${index}`}>
                            <div className="md:hidden flex">
                                {
                                    index === breadCrumb.length - 1 ? (
                                        <>
                                            <li className="ml-2 breadcrumb-item">
                                                <span className="fa-light fa-chevron-right text-muted"></span>
                                            </li>

                                            <li className="ml-2 breadcrumb-item">
                                                {
                                                    crumb.linkItem ? (
                                                        <Link to={crumb.url}>
                                                            <span className="lttr-spc">{crumb.title}</span>
                                                        </Link>
                                                    ) : (
                                                        <span className="lttr-spc">{crumb.title}</span>
                                                    )
                                                }
                                            </li>
                                        </>
                                    ) : index === breadCrumb.length - 2 ? (
                                        <>
                                            <li className="ml-2 breadcrumb-item">
                                                <span className="fa-light fa-chevron-right text-muted"></span>
                                            </li>

                                            <li className="ml-2 breadcrumb-item">
                                                <span className="lttr-spc">...</span>
                                            </li>
                                        </>
                                    ) : null
                                }
                            </div>

                            <div className="md:flex hidden">
                                <li className="ml-2 breadcrumb-item">
                                    <span className="fa-light fa-chevron-right text-muted"></span>
                                </li>

                                <li className="ml-2 breadcrumb-item">
                                    {
                                        crumb.linkItem ? (
                                            <Link to={crumb.url}>
                                                <span className="lttr-spc">{crumb.title}</span>
                                            </Link>
                                        ) : (
                                            <span className="lttr-spc">{crumb.title}</span>
                                        )
                                    }
                                </li>
                            </div>
                        </React.Fragment>
                    ))
                }
            </ul>
        </React.Fragment>
    )
}