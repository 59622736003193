import { Helmet } from "react-helmet"
import { jwtDecode } from "jwt-decode"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Navigate } from "react-router-dom"

import Config from "../../api/Config"
import { COOKIE_KEYS } from "../../utils/Constants"
import { Footer } from "../../components/layouts/Footer"
import CookieServices from "../../services/CookieServices"
import { revokeIdentity } from "../../redux/idenTT_Actions"

export const AccountStatus = () => {
    const dispatch: any = useDispatch();
    const statusKey = CookieServices.get(COOKIE_KEYS.STATUS)
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const decodedStatus: any = jwtDecode(statusKey);
    const accountStatus = decodedStatus.identity

    React.useEffect(() => {
        if (accountStatus === 'Active') {
            setShouldRedirect(true);
        }
    }, [accountStatus])

    if (shouldRedirect) {
        return <Navigate to="/home" replace />;
    }

    const signOutFromSession = () => {
        dispatch(revokeIdentity());
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{Config.APP_NAME} Account Status</title>
            </Helmet>

            <div className="flex items-center justify-center min-h-screen md:p-6 relative">
                <section className="md:w-[550px] w-full mx-auto py-8 z-[1]">
                    <div className="space-y-3 shadow-none border-0 border-slate-300 px-10 mb-3">
                        <div className="w-12/12 pb-4 pt-10 m-auto">
                            {/* <img src={emptyBox} alt="401_broken_robot" width="300px" className="block text-center m-auto" /> */}
                        </div>

                        <div className="m-auto">
                            <p className="text-left text-xl mb-2 text-red-600">
                                Your account has been {accountStatus.status.toLowerCase()}.
                            </p>

                            <p className="text-sm py-1 form-group mb-0 text-stone-600">
                                If you believe this is an error, kindly contact your system administrator for assistance.
                            </p>
                        </div>
                    </div>

                    <div className="pb-4 px-10 flex flex-row-reverse">
                        <div className="w-6/12">
                            <span className="text-sm float-right text-slate-600">
                                {/* authenticationState.accountName */}
                            </span>

                            <button onClick={() => signOutFromSession()} type="button" className="text-sm float-right text-red-600 hover:text-red-800">
                                Sign Out
                            </button>
                        </div>
                    </div>

                    <Footer />
                </section>
            </div>

        </React.Fragment>
    )
}