import clsx from 'clsx'
import ReactDOM from "react-dom"
import React, { FC } from "react"
import { toast } from "react-toastify"
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react"

import { Loading } from "../../components/utils/Loading"
import { Thematic } from '../../components/utils/Thematic'
import CookieServices from '../../services/CookieServices'
import { COOKIE_KEYS, APPLICATION } from '../../utils/Constants'

interface Props {
    size: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl',
    status: any,
    show: boolean,
    showOrHide: any,
    posting: boolean,
    noAction?: boolean,
    contents: any,
    formAction: string,
    onSubmit: any,
    header: any,
    error?: {
        title: string,
        message: any,
    },
}

export const TwoToneModal: FC<Props> = ({ show, size, showOrHide, onSubmit, posting, contents, formAction, status, error, noAction = false, header }) => {
    const theme = CookieServices.get(COOKIE_KEYS.THEME) || APPLICATION.THEME

    const checkIfFormIsPostingData = () => {
        if (!posting) {
            showOrHide()
            return
        }

        let toastText = 'Cannot dismiss. Current operation is still in progress'

        toast.warning(toastText, {
            position: "top-right",
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return ReactDOM.createPortal(
        <Dialog open={show} onClose={checkIfFormIsPostingData} className="relative z-20">
            <DialogBackdrop transition className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />

            <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center w-full text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className={clsx(
                            size === 'sm' ? 'md:max-w-sm' : null,
                            size === 'md' ? 'md:max-w-md' : null,
                            size === 'lg' ? 'md:max-w-lg' : null,
                            size === 'xl' ? 'md:max-w-xl' : null,
                            size === '2xl' ? 'md:max-w-2xl' : null,
                            size === '3xl' ? 'md:max-w-3xl' : null,
                            size === '4xl' ? 'md:max-w-4xl' : null,
                            size === '5xl' ? 'md:max-w-5xl' : null,
                            size === '6xl' ? 'md:max-w-6xl' : null,
                            "relative transform  w-full overflow-hidden md:rounded-lg bg-white dark:bg-slate-800 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95")}
                    >
                        <Thematic>
                            {
                                status === 'rejected' ? (
                                    <div className="">
                                        {
                                            error ? (
                                                <>
                                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                        <div className="sm:flex sm:items-start">
                                                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                                <span className="fal fa-exclamation-circle fa-2x text-red-600"></span>
                                                            </div>
                                                            <div className="mt-3 text-center text-slate-600 sm:mt-0 sm:ml-4 sm:text-left">
                                                                <span className="text-red-500 pb-4 pt-2 block">
                                                                    {error.title}
                                                                </span>

                                                                <div className="text-sm leading-7 mb-4">
                                                                    {error.message}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bg-slate-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                                        <button
                                                            type="button"
                                                            onClick={checkIfFormIsPostingData}
                                                            className="w-full inline-flex justify-center text-sm rounded-md border border-transparent shadow-sm px-3 py-1 bg-red-600 text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm">
                                                            Close
                                                        </button>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="bg-white flex text-red-600 md:flex-row align-middle items-center flex-col px-4 pt-3 pb-4">
                                                        <div className="flex-none flex align-middle justify-center items-center md:w-24 py-3 w-full">
                                                            <span className="fa-duotone fa-cloud-exclamation fa-3x"></span>
                                                        </div>

                                                        <div className="flex-grow flex flex-col items-center">
                                                            <div className="mt-3 m-auto text-slate-600">
                                                                <span className="text-red-600 text-lg mb-2 block">
                                                                    Communication Breakdown
                                                                </span>

                                                                <div className="text-sm">
                                                                    We had some trouble processing your request. Try again later
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="bg-slate-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                                        <div className="w-12/12 space-y-px">
                                                            <div className="flex flex-row-reverse items-center align-middle">
                                                                <button type="button" className="w-full inline-flex justify-center text-sm rounded-md border-0 border-transparent shadow-none px-3 py-1 bg-inherit text-primary hover:bg-slate-200 sm:ml-3 sm:w-auto sm:text-sm" onClick={checkIfFormIsPostingData}>
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                ) : status === 'fulfilled' ? (
                                    <form className="rounded-md h-screen md:h-auto shadow-none space-y-px flex flex-col dark:text-gray-300 md:bg-gray-100 dark:md:bg-gray-900" onSubmit={onSubmit}>
                                        <div className="flex-grow flex flex-col md:flex-row md:px-2.5 md:pt-2.5 overflow-y-auto">
                                            <div className={
                                                clsx(
                                                    theme === 'red' ? 'md:bg-inherit dark:md:bg-inherit bg-light dark:bg-red-700/70' : null,
                                                    theme === 'orange' ? 'md:bg-inherit dark:md:bg-inherit bg-light dark:bg-orange-700/70' : null,
                                                    theme === 'yellow' ? 'md:bg-inherit dark:md:bg-inherit bg-light dark:bg-yellow-700/70' : null,
                                                    theme === 'lime' ? 'md:bg-inherit dark:md:bg-inherit bg-light dark:bg-lime-700/70' : null,
                                                    theme === 'green' ? 'md:bg-inherit dark:md:bg-inherit bg-light dark:bg-green-700/70' : null,
                                                    theme === 'teal' ? 'md:bg-inherit dark:md:bg-inherit bg-light dark:bg-teal-700/70' : null,
                                                    theme === 'cyan' ? 'md:bg-inherit dark:md:bg-inherit bg-light dark:bg-cyan-700/70' : null,
                                                    theme === 'blue' ? 'md:bg-inherit dark:md:bg-inherit bg-light dark:bg-blue-700/70' : null,
                                                    theme === 'purple' ? 'md:bg-inherit dark:md:bg-inherit bg-light dark:bg-purple-700/70' : null,
                                                    "md:w-1/3 flex-none w-full px-2.5 md:px-0 pb-4 pt-2 sm:pb-4"
                                                )
                                            }>
                                                {
                                                    header
                                                }
                                            </div>

                                            <div className="md:w-2/3 flex-grow bg-white dark:bg-gray-800 md:border dark:md:border-gray-700 border-gray-300 rounded-md w-full pt-4 pb-2 sm:pt-4 sm:pb-2">
                                                <div className="w-full">
                                                    <div className="w-full min-h-auto md:h-[480px]">
                                                        {
                                                            contents
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse md:bg-inherit bg-gray-100 dark:bg-gray-900 dark:md:bg-inherit">
                                            <div className="w-full space-y-px">
                                                <div className="flex flex-row-reverse items-center align-middle">
                                                    {
                                                        noAction ? (
                                                            <button type="button" className="w-24 justify-center disabled:cursor-not-allowed text-sm rounded-md border border-transparent shadow-sm px-3 py-1-5 bg-primary text-white disabled:bg-primary hover:bg-secondary focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-primary" onClick={showOrHide}>
                                                                Done
                                                            </button>
                                                        ) : (
                                                            <>
                                                                <button type="submit" className="w-auto min-w-24 justify-center disabled:cursor-not-allowed text-sm rounded-md border border-transparent shadow-sm px-3 py-2 bg-primary dark:bg-secondary text-white hover:bg-secondary dark:hover:bg-primary focus:outline-none">
                                                                    {
                                                                        posting ? (
                                                                            <span className="flex flex-row items-center h-5 justify-center">
                                                                                <i className="fad fa-spinner-third fa-xl fa-spin"></i>
                                                                            </span>
                                                                        ) : (
                                                                            formAction
                                                                        )
                                                                    }
                                                                </button>

                                                                <button type="button" className="text-sm flex-none shadow-none px-3 py-1 bg-inherit text-primary dark:text-gray-400 hover:text-secondary dark:hover:text-gray-300 hover:underline hover:cursor-pointer mr-2 sm:w-auto sm:text-sm" onClick={checkIfFormIsPostingData}>
                                                                    Cancel
                                                                </button>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                ) : (
                                    <div className="py-2 px-6">
                                        <Loading />
                                    </div>
                                )
                            }
                        </Thematic>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>,
        document.getElementById('root')
    )
}