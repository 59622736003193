import Cookie from "universal-cookie"
const cookie = new Cookie()

class CookieService {
    get (key: string) {
        return cookie.get(key)
    }

    set (key: string, value: any, options: object) {
        cookie.set(key, value, options)        
    }

    remove (key: string, minutes: any = 60,) {
        const date = new Date()
        date.setTime(date.getTime() - minutes * 60 * 1000)
        cookie.set(key, null, { path: '/', expires: date })
    }
}

const cookieServiceInstance = new CookieService()
export default cookieServiceInstance