import { Helmet } from "react-helmet"
import { useParams } from "react-router"
import React, { useEffect, useState } from "react"

import Config from "../../api/Config"
import { CreateType } from "./CreateType"
import { Attributes } from "./Attributes"
import { ASSETS } from "../../api/Registry"
import HttpServices from "../../services/HttpServices"
import { LoadingXL } from "../../components/utils/LoadingXL"
import { API_RouteReplace, classNames } from "../../utils/Helpers"

export const AssetTypesListing = () => {
    const [state, setstate] = useState({
        reload: false,
        httpStatus: 200,
        status: 'pending',
        data: {
            types: null,
            category: null,
        },
        show: {
            createType: false,
            createCategory: false,
        }
    })

    const [activeType, setActiveType] = React.useState(null)
    const [selectedAssetType, setSelectedAssetType] = useState(null)
    const [newAssetType, setNewAssetType] = React.useState(null)

    let param = useParams()
    let pageTitle = "Asset Configuration"

    useEffect(() => {
        const fetchAssetTypes = async () => {
            let { status } = state
            let { httpStatus } = state

            let types = null
            let category = null

            try {
                let response: any = null
                let assetTypesRoute = null

                assetTypesRoute = API_RouteReplace(ASSETS.CATEGORY_TYPES, ':category', param.uuid)
                response = await HttpServices.httpGet(assetTypesRoute)

                httpStatus = response.status

                if (httpStatus === 200) {
                    const payload: any = response.data.payload

                    types = payload.types
                    category = payload.category

                    pageTitle = category.name
                    status = 'fulfilled'
                } else {
                    status = 'rejected'
                }
            } catch (error) {
                status = 'rejected'
                httpStatus = 500
            }

            setstate((prevState: any) => ({
                ...prevState, status,
                httpStatus,
                data: {
                    ...prevState.data,
                    types: types,
                    category: category
                },
            }));
        }

        fetchAssetTypes()
    }, [])

    const toggleCreateTypePanel = () => {
        setstate((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                createType: !prev.show.createType
            }
        }))
    }

    const onCreateTypeComplete = (newType: any) => {
        toggleCreateTypePanel()
        setNewAssetType(newType)
    }

    const assetTypeSelector = (assetType: any) => {
        setActiveType(assetType)
        setSelectedAssetType(assetType)
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {pageTitle} - {Config.APP_NAME}
                </title>
            </Helmet>

            {
                state.status === 'rejected' ? (
                    <>

                    </>
                ) : state.status === 'fulfilled' ? (
                    <>
                        <div className="flex flex-col lg:flex-row md:h-full lg:h-full text-sm">
                            <div className="lg:w-1/4 bg-inherit p-4 lg:border-r">
                                <p className="text-xl md:mb-3 text-theme text-wrap">
                                    {state.data.category.name}
                                </p>

                                <p className="text-xl md:mb-3 text-wrap">
                                    <span className="block py-1 5 text-sm">
                                        Click on a type to view/set attributes
                                    </span>
                                </p>

                                <div className="flex flex-row-reverse w-full pr-2 py-4 md:py-0 md:pb-4">
                                    <button type="button" onClick={toggleCreateTypePanel} className="flex flex-row align-middle items-center bg-primary disabled:cursor-not-allowed py-1 px-2.5 rounded-md text-white gap-x-1.5 text-sm">
                                        <span className="text-sm">Add Asset Type</span>
                                    </button>
                                </div>

                                <ul>
                                    {
                                        newAssetType && (
                                            <li onClick={() => assetTypeSelector(newAssetType)} className={
                                                classNames(
                                                    activeType && activeType.uuid === newAssetType.uuid ? 'bg-light hover:text-secondary rounded-md' : 'hover:bg-gray-100 hover:text-gray-800',
                                                    "px-2 py-3 border-b last:border-b-0 border-gray-300 dark:border-gray-600 hover:rounded-md text-start w-full cursor-pointer"
                                                )
                                            } key={newAssetType.uuid}>
                                                <h3 className="font-normal">{newAssetType.name}</h3>
                                            </li>
                                        )
                                    }

                                    {
                                        state.data.types.map((type: any) => {
                                            return (
                                                <li key={type.uuid} onClick={() => assetTypeSelector(type)} className={
                                                    classNames(
                                                        activeType && activeType.uuid === type.uuid ? 'bg-light dark:text-theme-dark hover:text-secondary rounded-md' : 'hover:bg-gray-100 dark:hover:text-primary dark:hover:bg-gray-600 hover:text-gray-800',
                                                        "px-2 py-3 border-b last:border-b-0 border-gray-300 dark:border-gray-600 hover:rounded-md text-start w-full cursor-pointer"
                                                    )
                                                }>
                                                    <h3 className="font-normal">{type.name}</h3>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>

                            <div className={`lg:flex-1 lg:overflow-y-auto transition-all pb-10 ${selectedAssetType ? "block" : " lg:block"}`}>
                                <Attributes
                                    category={state.data.category}
                                    type={selectedAssetType}
                                />
                            </div>
                        </div>

                        <CreateType
                            category={state.data.category}
                            show={state.show.createType}
                            onComplete={onCreateTypeComplete}
                            showOrHide={toggleCreateTypePanel}
                        />
                    </>
                ) : (
                    <div className="flex-grow w-full overflow-auto h-full flex flex-row py-4 justify-center align-middle items-center">
                        <LoadingXL />
                    </div>
                )
            }
        </React.Fragment>
    )
}