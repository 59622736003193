
/***************************
* Authentication API Routes
***************************/

export const SANCTUM = {
    _COOKIE:            'sanctum/csrf-cookie',
    _USER:              'api/user',
    _SIGN_OUT:          ''
}

export const AUTH = {
    SS1_BENEFACTORS:    '/api/v1/identity/auth/_/ss1/benefactor',
    BENEFACTOR_STATUS:  '/api/v1/identity/auth/_/benefactor/status',
};


export const TEMP = {
    SS2_BENEFACTORS:    '/api/temp/v1/identity/auth/_/ss2/benefactor',
}

/* 
 * Asset Management API Routes
*/
export const ASSETS = {
    ALL_CATEGORIES:     '/api/v1/assets-management/categories',
    CATEGORY_TYPES:     '/api/v1/assets-management/categories/:category/types',
    ASSET_FORMS:        '/api/v1/assets-management/categories/:category/types/:type/forms',
    FORM_ATTRIBUTES:    '/api/v1/assets-management/categories/:category/types/:type/attributes',
}