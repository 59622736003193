import React from "react"

import Config from "../../api/Config"

export const Footer = () => {
    return (
        <React.Fragment>
            <footer className="py-4 md:text-center text-left text-sm text-gray-600 md:mx-0 mx-4 md:px-0 px-3 md:border-t-2 border-dashed">
                <p className="mb-2 px-0 text-primary md:text-sm text-base md:border-b-0 border-b pb-4">© {new Date().getFullYear()}. {Config.APP_NAME}.</p>

                <div className="flex flex-col md:flex-row md:justify-between md:gap-x-4 gap-y-3 py-3 md:py-0 w-full">
                    <a href="/terms" className="text-gray-500 hover:text-gray-900">Terms of Service</a>
                    <a href="/privacy" className="text-gray-500 hover:text-gray-900">Privacy Policy</a>
                    <a href="/help" className="text-gray-500 hover:text-gray-900">Help</a>
                </div>
            </footer>
        </React.Fragment>
    )
}