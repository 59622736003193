import { MenuItem } from '@headlessui/react'
import React, { useEffect, useState } from "react"

import { ASSETS } from "../../api/Registry"
import { AddAttribute } from "./AddAttribute"
import DropDown from "../../lib/fragments/DropDown"
import HttpServices from "../../services/HttpServices"
import { API_RouteReplace } from "../../utils/Helpers"
import { Loading } from "../../components/utils/Loading"
import { ModuleBreadCrumb } from "../../lib/fragments/ModuleBreadCrumb"

export const Attributes = ({ category, type }) => {
    const [state, setstate] = useState({
        httpStatus: 200,
        status: 'pending',
        data: {

        },
        show: {
            addAttribute: false
        }
    })

    useEffect(() => {
        if (type) {
            const fetchAssetTypeForm = async () => {
                setstate((prevState: any) => ({
                    ...prevState,
                    status: 'pending'
                }))

                let forms = null
                let { status } = state
                let { httpStatus } = state

                try {
                    let response: any = null
                    let assetFormRoute = null

                    assetFormRoute = API_RouteReplace(ASSETS.ASSET_FORMS, ':category', category.uuid)
                    assetFormRoute = API_RouteReplace(assetFormRoute, ':type', type.uuid)

                    response = await HttpServices.httpGet(assetFormRoute)
                    httpStatus = response.status

                    if (httpStatus === 200) {
                        const payload: any = response.data.payload

                        status = 'fulfilled'
                        forms = payload.forms
                    } else {
                        status = 'rejected'
                    }
                } catch (error) {
                    console.log(error);

                    status = 'rejected'
                    httpStatus = 500
                }

                setstate((prevState: any) => ({
                    ...prevState, status,
                    httpStatus,
                    data: {
                        ...prevState.data,
                        forms: forms,
                    },
                }));
            }

            fetchAssetTypeForm()
        }
    }, [category, type])

    function setBreadCrumb() {
        let breadCrumb = [
            { linkItem: false, title: "Asset Management" },
            { linkItem: false, title: "Configuration" },
        ]

        if (category) {
            breadCrumb = [
                { linkItem: false, title: "Asset Management" },
                { linkItem: false, title: category.name },
            ]

            if (type) {
                breadCrumb = [
                    ...breadCrumb,
                    { linkItem: true, title: type.name }
                ];
            }
        }

        return breadCrumb
    }

    const toggleAddAttributeModal = () => {
        setstate((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                addAttribute: !prev.show.addAttribute
            }
        }))
    }

    return (
        <React.Fragment>
            {
                type ? (
                    <>
                        <div className="w-full h-full bg-inherit rounded-md flex flex-col px-5">
                            <div className="flex-none w-full lg:w-2/3 mx-auto py-2">
                                <div className="promotion flex flex-col relative z-[3] w-full">
                                    <div className="header-container opacity-100 flex flex-col justify-center flex-1">
                                        <ModuleBreadCrumb
                                            breadCrumb={setBreadCrumb()}
                                        />
                                    </div>
                                </div>

                                <div className="w-full pb-3 mb-3 border-b-2 border-dashed border-gray-300">
                                    <p className="text-lg text-theme font-medium text-start">
                                        {type.name}
                                    </p>

                                    <p className="text-sm text-wrap">
                                        {type.description}
                                    </p>
                                </div>

                                <div className="w-full flex flex-row align-middle items-center py-2 md:py-1">
                                    <div className="md:w-1/2 w-full flex flex-row gap-x-3 md:hidden">
                                        <div className="tooltip">
                                            <button type="button" className="flex flex-row align-middle items-center gap-x-1.5">
                                                <span className="text-sm">Undo</span>
                                                <span className="fa-duotone fa-rotate-left fa-lg"></span>
                                            </button>

                                            <span className="tooltip-text z-20 text-xs">Undo Changes</span>
                                        </div>

                                        <span className="border-l border-gray-300"></span>

                                        <div className="tooltip">
                                            <button type="button" className="flex flex-row align-middle items-center gap-x-1.5">
                                                <span className="fa-duotone fa-rotate-right fa-lg"></span>
                                                <span className="text-sm">Redo</span>
                                            </button>

                                            <span className="tooltip-text z-20 text-xs">Redo Changes</span>
                                        </div>
                                    </div>

                                    <div className="flex-none pb-3 hidden md:block">
                                        <button type="button" onClick={toggleAddAttributeModal} className="flex flex-row align-middle items-center gap-x-1.5 text-theme hover:text-theme">
                                            <span className="fa-regular fa-plus"></span>
                                            <span className="text-sm">Add Attribute</span>
                                        </button>
                                    </div>

                                    <div className="md:w-full flex flex-row-reverse gap-x-3 items-center">
                                        <DropDown
                                            button={undefined}
                                            items={
                                                <div className="text-sm w-48 flex flex-col gap-y-1">
                                                    <MenuItem>
                                                        <button type="button" className="w-full flex gap-x-3 items-center align-middle rounded-md px-2 py-2 text-sm text-primary data-[focus]:bg-gray-200 data-[focus]:text-secondary">
                                                            <div className="flex-none w-1/4 flex items-center justify-center">
                                                                <span className="fa-duotone fa-eye fa-lg block"></span>
                                                            </div>

                                                            <span className="w-3/4 text-start">Preview</span>
                                                        </button>
                                                    </MenuItem>

                                                    <MenuItem>
                                                        <button type="button" className="w-full flex gap-x-3 items-center rounded-md px-2 py-2 text-sm text-primary data-[focus]:bg-gray-200 data-[focus]:text-secondary">
                                                            <div className="flex-none w-1/4 flex items-center justify-center">
                                                                <span className="fa-light fa-pen-to-square fa-lg block"></span>
                                                            </div>

                                                            <span className="w-3/4 text-start">Edit</span>
                                                        </button>
                                                    </MenuItem>

                                                    <span className="border-b border-gray-300 mx-3"></span>

                                                    <MenuItem>
                                                        <button type="button" className="w-full flex gap-x-3 items-center rounded-md px-2 py-2 text-sm text-red-600 data-[focus]:bg-red-200 data-[focus]:text-red-700">
                                                            <div className="flex-none w-1/4 flex items-center justify-center">
                                                                <span className="fa-light fa-trash fa-lg block"></span>
                                                            </div>
                                                            <span className="w-3/4 text-start">Decommission</span>
                                                        </button>
                                                    </MenuItem>

                                                    <MenuItem>
                                                        <button type="button" className="w-full flex gap-x-3 items-center rounded-md px-2 py-2 text-sm text-primary data-[focus]:bg-gray-200 data-[focus]:text-secondary">
                                                            <div className="flex-none w-1/4 flex items-center justify-center">
                                                                <span className="fa-light fa-circle-info fa-lg block"></span>
                                                            </div>

                                                            <span className="w-3/4 text-start">Help</span>
                                                        </button>
                                                    </MenuItem>
                                                </div>
                                            }
                                        />

                                        <span className="border-l py-3 border-gray-300"></span>

                                        <button type="button" disabled className="flex flex-row align-middle items-center bg-primary disabled:cursor-not-allowed py-1 px-2.5 rounded-md text-white gap-x-1.5 text-sm">
                                            <span className="text-sm">Publish</span>
                                            <span className="fa-duotone fa-circle-check"></span>
                                        </button>

                                        <div className="md:flex hidden align-middle items-center gap-x-2 mr-2">

                                            <div className="tooltip mr-1 md:block hidden">
                                                <button type="button" className="block">
                                                    <span className="fa-duotone fa-rotate-left fa-xl"></span>
                                                </button>

                                                <span className="tooltip-text z-10 text-xs">Undo Changes</span>
                                            </div>

                                            <div className="tooltip">
                                                <button type="button" className="block">
                                                    <span className="fa-duotone fa-rotate-right fa-xl"></span>
                                                </button>

                                                <span className="tooltip-text z-10 text-xs">Redo Changes</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-grow overflow-y-auto w-full">
                                {
                                    state.status === 'rejected' ? (
                                        <>

                                        </>
                                    ) : state.status === 'fulfilled' ? (
                                        <>
                                            <div className="lg:w-2/3 w-full overflow-y-auto mx-auto">
                                                <div className="w-full pt-2 pb-3 md:hidden block">
                                                    <button type="button" onClick={toggleAddAttributeModal} className="flex flex-row align-middle items-center gap-x-1.5 text-theme hover:text-theme">
                                                        <span className="fa-regular fa-plus"></span>
                                                        <span className="text-sm">Add Attribute</span>
                                                    </button>
                                                </div>

                                                





                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="w-full h-full flex flex-row py-4 justify-center align-middle items-center">
                                                <Loading />
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="text-center w-full h-full bg-inherit rounded-md flex flex-col px-4">
                        {/* <div className="border-t lg:border-0 border-gray-300 dark:border-gray-600"></div> */}
                        <div className="w-full flex-none py-2 border-b-2 border-dashed border-gray-400 flex md:flex-row flex-col align-middle lg:items-center">
                            <div className="flex-grow">
                                <p className="text-xl text-primary font-medium text-start">
                                    Attributes
                                </p>
                            </div>

                            <div className="flex-grow flex flex-row-reverse align-middle items-center">
                                <div className="w-auto flex flex-row align-middle items-center gap-x-2">
                                    <div className="tooltip">
                                        <button type="button" disabled className="bg-gray-300 disabled:cursor-not-allowed border-gray-200 py-1.5 px-2 rounded-md text-primary gap-x-3 align-middle text-sm">
                                            <i className="fa-duotone fa-rotate-left fa-lg"></i>
                                        </button>

                                        <span className="tooltip-text z-20 bg-blue-500 text-xs">Undo Changes</span>
                                    </div>

                                    <div className="tooltip">
                                        <button type="button" disabled className="bg-gray-300 disabled:cursor-not-allowed border-gray-200 py-1.5 px-2 rounded-md text-primary gap-x-3 align-middle text-sm">
                                            <i className="fa-duotone fa-rotate-right fa-lg"></i>
                                        </button>

                                        <span className="tooltip-text z-20 bg-blue-500 text-xs">Redo Changes</span>
                                    </div>

                                    <button type="button" disabled className="bg-gray-300 disabled:cursor-not-allowed py-1.5 px-2 rounded-md text-muted gap-x-3 align-middle text-sm">
                                        Publish Changes
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="flex-grow w-full flex items-center justify-center">
                            <div>
                                <div role="status" className="w-64 h-64 bg-geen-100 rounded relative">
                                    <div className="absolute w-44 rounded-full bg-gray-200 h-44 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"></div>
                                    <div className="w-2/3 h-28 rounded-md bg-white shadow-lg absolute p-3 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"></div>

                                    <div className="absolute w-full bg-inherit z-10 px-4 h-full flex flex-col items-center justify-center">
                                        <div className="w-full bg-white shadow-lg relative">

                                        </div>

                                        <div className="w-full border border-gray-100 shadow-lg rounded-lg p-2 bg-white flex items-center gap-x-2">
                                            <div className="flex-none w-16 rounded-md h-16 bg-gray-200"></div>

                                            <div className="flex-grow w-full flex flex-col gap-y-3">
                                                <div className="h-2.5 bg-gray-200 rounded-full w-2/3"></div>
                                                <div className="h-2.5 bg-gray-200 rounded-full w-full"></div>
                                                <div className="h-2.5 bg-gray-200 rounded-full w-full"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <span className="block text-base text-primary font-medium">
                                    Select an asset type from the list
                                </span>
                                <span className="block text-muted">
                                    to view configured details
                                </span>
                            </div>
                        </div>
                    </div>
                )
            }

            <AddAttribute
                onComplete={undefined}
                show={state.show.addAttribute}
                showOrHide={toggleAddAttributeModal}
            />
        </React.Fragment>
    )
}