import axios from "axios";

import Config from "../api/Config";
import { DeviceInfo } from "../utils/Helpers";
import CookieServices from "./CookieServices";

class AxiosServices {
    async httpGet(url: string) {
        try {
            const GET_API_URL = Config.APP_CORE + url
            return await axios.get(GET_API_URL, this.axiosInstanceHeaders())
        } catch (error: any) {
            console.error('Error in httpGet:', error);
            return error
        }
    }

    async httpPost(url: string, data: any, options: any = null) {
        try {
            const finalOptions = Object.assign(this.axiosInstanceHeaders(), options)
            const POST_API_URL = Config.APP_CORE + url

            return await axios.post(POST_API_URL, data, finalOptions);
        } catch (error: any) {
            console.error('Error in httpGet:', error);
            return error
        }
    }

    async httpPut(url: string, data: any, options: any = null) {
        try {
            const finalOptions = Object.assign(this.axiosInstanceHeaders(), options)
            const PUT_API_URL = Config.APP_CORE + url

            return await axios.put(PUT_API_URL, data, finalOptions);
        } catch (error: any) {
            console.error('Error in httpGet:', error);
            return error
        }
    }

    async httpDelete(url: string, options: any = null) {
        try {
            const finalOptions = Object.assign(this.axiosInstanceHeaders(), options)
            const DEL_API_URL = Config.APP_CORE + url

            return await axios.delete(DEL_API_URL, finalOptions);
        } catch (error: any) {
            console.error('Error in httpGet:', error);
            return error
        }
    }

    async httpMultipartForm(url: string, data: any, options: any = null) {
        try {
            const finalOptions = Object.assign(this.axiosInstanceHeaders(true), options)
            const POST_API_URL = Config.APP_CORE + url

            return await axios.post(POST_API_URL, data, finalOptions);
        } catch (error: any) {
            console.error('Error in httpGet:', error);
            return error
        }
    }

    axiosInstanceHeaders(multipart: boolean = false) {
        const deviceData = DeviceInfo()
        const xsrfToken = CookieServices.get('XSRF-TOKEN')
        const contentType = multipart === true
            ? 'multipart/form-data'
            : 'application/json'

        return {
            headers: {
                'X-XSRF-TOKEN': xsrfToken,
                'User-Device': deviceData,
                'Content-Type': contentType,
            }
        }
    }
}

const axiosServicesInstance = new AxiosServices()
export default axiosServicesInstance