import React, { FC } from "react"
import { Link } from "react-router-dom"

import { generateUniqueKey } from "../../utils/Helpers"
import { ModuleHeaderProps } from "../../utils/Interfaces"

export const ModuleHeader: FC<ModuleHeaderProps> = ({
    breadCrumb, pageTitle, description
}) => {

    return (
        <React.Fragment>
            <div className="promotion flex flex-col relative z-[3] w-full pt-4">
                <div className="header-container opacity-100 flex flex-col justify-center flex-1">
                    <ul className="w-full flex flex-wrap items-center pb-2 text-xs text-muted dark:text-gray-400">
                        <li>
                            <span className="h-5 flex gap-x-2 items-center">
                                <span className="fa-light fa-home"></span> Home
                            </span>
                        </li>

                        {
                            breadCrumb.map((crumb: any, index: number) => (
                                <React.Fragment key={`bread-crumb-${index}`}>
                                    <div className="md:hidden flex">
                                        {
                                            index === breadCrumb.length - 1 ? (
                                                <>
                                                    <li className="ml-2 breadcrumb-item">
                                                        <span className="fa-light fa-chevron-right text-muted"></span>
                                                    </li>

                                                    <li className="ml-2 breadcrumb-item">
                                                        {
                                                            crumb.linkItem ? (
                                                                <Link to={crumb.url}>
                                                                    <span className="lttr-spc">{crumb.title}</span>
                                                                </Link>
                                                            ) : (
                                                                <span className="lttr-spc ">{crumb.title}</span>
                                                            )
                                                        }
                                                    </li>
                                                </>
                                            ) : index === breadCrumb.length - 2 ? (
                                                <>
                                                    <li className="ml-2 breadcrumb-item">
                                                        <span className="fa-light fa-chevron-right text-muted"></span>
                                                    </li>

                                                    <li className="ml-2 breadcrumb-item ">
                                                        <span className="lttr-spc">...</span>
                                                    </li>
                                                </>
                                            ) : null
                                        }
                                    </div>

                                    <div className="md:flex hidden">
                                        <li className="ml-2 breadcrumb-item">
                                            <span className="fa-light fa-chevron-right text-muted"></span>
                                        </li>

                                        <li className="ml-2 breadcrumb-item">
                                            {
                                                crumb.linkItem ? (
                                                    <Link to={crumb.url}>
                                                        <span className="lttr-spc">{crumb.title}</span>
                                                    </Link>
                                                ) : (
                                                    <span className="lttr-spc ">{crumb.title}</span>
                                                )
                                            }
                                        </li>
                                    </div>
                                </React.Fragment>
                            ))
                        }
                    </ul>

                    <div className="header-title align-middle flex flex-nowrap title-transition">
                        <h1 className="title md:text-2xl text-xl tracking-wider leading-9 md:pb-4 pb-3 text-theme dark:text-theme">
                            {pageTitle}
                        </h1>
                    </div>
                </div>

                <p className="text-sm md:mb-5 text-wrap">
                    {description}
                </p>
            </div>
        </React.Fragment>
    )
}