import axios from "axios"

import Config from "../api/Config"
import { store } from "../redux/store"
import Crypto from "../security/Crypto"
import CookieServices from "./CookieServices"
import { DeviceInfo } from "../utils/Helpers"
import { COOKIE_KEYS } from "../utils/Constants"
import { revokeIdentity } from "../redux/idenTT_Actions"

class HttpServices {
    protected decryptSanctumTokenCookie() {
        const cipherText = CookieServices.get(COOKIE_KEYS.SANCTUM)

        return (cipherText != null)
            ? Crypto.decryptDataUsingAES256(cipherText)
            : null
    }

    async httpGet(url: string) {
        try {
            const GET_API_URL = Config.APP_CORE + url
            return await axios.get(GET_API_URL, this.axiosInstanceHeaders())
        } catch (error: any) {
            const ERR = {
                code: error.code,
                message: error.message,
                data: error.response?.data,
                status: error.response?.status,
            }
            
            if (axios.isAxiosError(error)) {
                let statusCode = error.response?.status;

                if (statusCode === 401) {
                    return this.revokeAuthenticatedSession();
                } else {
                    return ERR;
                }
            } else {
                return ERR;
            }
        }
    }

    async httpPost(url: string, data: any, options: any = null) {
        try {
            const finalOptions = Object.assign(this.axiosInstanceHeaders(), options)
            const POST_API_URL = Config.APP_CORE + url

            return await axios.post(POST_API_URL, data, finalOptions);
        } catch (error: any) {
            const ERR = {
                code: error.code,
                message: error.message,
                data: error.response?.data,
                status: error.response?.status,
            }
            
            if (axios.isAxiosError(error)) {
                let statusCode = error.response?.status;

                if (statusCode === 401) {
                    return this.revokeAuthenticatedSession();
                } else {
                    return ERR;
                }
            } else {
                return ERR;
            }
        }
    }

    async httpPut(url: string, data: any, options: any = null) {
        try {
            const finalOptions = Object.assign(this.axiosInstanceHeaders(), options)
            const PUT_API_URL = Config.APP_CORE + url

            return await axios.put(PUT_API_URL, data, finalOptions);
        } catch (error: any) {
            const ERR = {
                code: error.code,
                message: error.message,
                data: error.response?.data,
                status: error.response?.status,
            }
            
            if (axios.isAxiosError(error)) {
                let statusCode = error.response?.status;

                if (statusCode === 401) {
                    return this.revokeAuthenticatedSession();
                } else {
                    return ERR;
                }
            } else {
                return ERR;
            }
        }
    }

    async httpDelete(url: string, options: any = null) {
        try {
            const finalOptions = Object.assign(this.axiosInstanceHeaders(), options)
            const DEL_API_URL = Config.APP_CORE + url

            return await axios.delete(DEL_API_URL, finalOptions);
        } catch (error: any) {
            const ERR = {
                code: error.code,
                message: error.message,
                data: error.response?.data,
                status: error.response?.status,
            }
            
            if (axios.isAxiosError(error)) {
                let statusCode = error.response?.status;

                if (statusCode === 401) {
                    return this.revokeAuthenticatedSession();
                } else {
                    return ERR;
                }
            } else {
                return ERR;
            }
        }
    }

    async httpMultipartForm(url: string, data: any, options: any = null) {
        try {
            const finalOptions = Object.assign(this.axiosInstanceHeaders(true), options)
            const POST_API_URL = Config.APP_CORE + url

            return await axios.post(POST_API_URL, data, finalOptions);
        } catch (error: any) {
            const ERR = {
                code: error.code,
                message: error.message,
                data: error.response?.data,
                status: error.response?.status,
            }
            
            if (axios.isAxiosError(error)) {
                let statusCode = error.response?.status;

                if (statusCode === 401) {
                    return this.revokeAuthenticatedSession();
                } else {
                    return ERR;
                }
            } else {
                return ERR;
            }
        }
    }

    axiosInstanceHeaders(multipart: boolean = false) {
        const deviceData = DeviceInfo()
        const xsrfToken = CookieServices.get('XSRF-TOKEN')
        const contentType = multipart === true
            ? 'multipart/form-data'
            : 'application/json'

        return {
            headers: {
                'X-XSRF-TOKEN': xsrfToken,
                'User-Device': deviceData,
                'Content-Type': contentType,
            }
        }
    }

    revokeAuthenticatedSession() {
        store.dispatch(revokeIdentity())
    }
}

const httpServicesInstance = new HttpServices()
export default httpServicesInstance